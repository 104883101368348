.loginWrapper {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
}

.loginWrapper :global button {
    width: 100%;
    margin-top: 1rem;
    max-width: 300px;
}
