.contactIcon {
    font-size: 12px;
    margin-right: 10px;
}
.phoneContact {
    margin-right: 20px;
}

.clinicTitle {
    margin-bottom: 0 !important;
}

@media all and (max-width: 992px) {
    .contactSection {
        margin-top: 1rem;
    }
}
