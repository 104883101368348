.contactIcon {
    font-size: 12px;
    margin-right: 10px;
}
.phoneContact {
    margin-right: 20px;
}
:global(.ant-form-item-with-help .ant-form-item-explain) {
    color: #faad14;
}
@media all and (max-width: 992px) {
    .contactSection {
        margin-top: 1rem;
    }
}
@media all and (max-width: 992px) {
    .addressSection {
        margin-bottom: 0.4rem;
    }
}
