.medication-modal__dose-alert-title {
    color: var(--secondary-red);
}

.medication-modal__dose-alert-section .ant-form-item,
.medication-modal__dose-alert-section .ant-checkbox-wrapper {
    margin: var(--spacing-sm) 0;
}

.medication-modal__dose-alert-section .ant-form-item-control-input-content {
    display: flex;
    flex-direction: column;
}
