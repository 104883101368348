.package-item-type-subtitle {
    display: flex;
    align-items: center;
    gap: 0 10px;
}

.package-item-type-subtitle b {
    margin-right: 5px;
}

.package-item-type-color {
    display: inline-block;
    width: 10px;
    height: 10px;
}

.package-medication-item {
    background-color: #4dd2a8;
}

.package-diagnostic-item {
    background-color: #ac89cc;
}

.package-fluids-item {
    background-color: #ffab61;
}

.package-tasks-item {
    background-color: #6ebdf5;
}

.package-non-medical-item {
    background-color: #ffa39e;
}
