/* Global variables */
:root {
    /* Colors */
    --gold-1: #fffbe6;
    --gold-3: #ffe58f;
    --gold-6: #faad14;
    --gray-2: #fafafa;
    --gray-3: #f5f5f5;
    --gray-4: #f0f0f0;
    --gray-5: #d9d9d9;
    --gray-6: #bfbfbf;
    --gray-7: #8c8c8c;
    --gray-8: #595959;
    --gray-9: #262626;
    --green-1: #f6ffed;
    --green-3: #b7eb8f;
    --green-6: #52c41a;
    --red-1: #fff1f0;
    --red-3: #ffa39e;
    --red-5: #ff4d4f;
    --red-6: #f5222d;
    --antd-blue: #1890ff;
    --veg-red: #e7202e;
    --veg-light-blue: #f0f5f7;
    --veg-tint-blue: #4878bb;
    --veg-secondary-blue: #1e387b;
    --veg-tint-plum: #bb8bb3;
    --veg-secondary-plum: #8a1e61;
    --veg-tint-lime: #dfe67d;
    --veg-secondary-lime: #b2d235;
    --veg-tint-teal: #76cfe7;
    --veg-secondary-teal: #009db9;
    --veg-secondary-gold: #fbad18;
    --veg-green: #26a416;
    --secondary-red: #ba141a;

    /* Fonts configs */
    --font-regular: 400;
    --font-medium: 500;
    --font-semi-bold: 600;
    --font-bold: 700;

    /* Spacing */
    --spacing-xs: 4px;
    --spacing-sm: 8px;
    --spacing-md: 16px;
    --spacing-lg: 24px;
    --spacing-xl: 40px;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
