.searchInput {
    width: 280px;
}
@media all and (max-width: 992px) {
    .searchInput {
        margin-bottom: 10px;
        width: 100%;
    }
}
@media all and (max-width: 992px) {
    .myHospitalsCheck {
        margin-bottom: 10px;
    }
}
@media all and (max-width: 992px) {
    .addClinicModal {
        width: 100%;
    }
}

/* UploadDraftsRdvmModal.tsx */ 

.templateLink {
    color: #1890FF;
}

.uploadDragger {
    background-color: #F5F5F5;
    border-color: #D9D9D9;
    border-style: dashed;
    margin-top: 28px;
}

.inboxIcon {
    color: #40A9FF;
}

.ant-upload-text {
    color: #262626;
}

.ant-upload-subtext {
    color: #8C8C8C;
}
