.topNavWrapper {
    height: 64px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #d32027;
}
.hamburgerMenu {
    display: block;
    color: #fff;
    background-color: transparent;
}
.hamburgerMenu:active,
.hamburgerMenu:focus {
    background-color: transparent;
    color: #fff;
    border-color: #fff;
}
.hamburgerMenu:hover {
    background-color: rgba(255, 255, 255, 0.11);
}
@media all and (min-width: 992px) {
    .hamburgerMenu {
        display: none;
    }
}
@media all and (max-width: 992px) {
    .antLayoutSider {
        position: absolute;
        z-index: 1;
        height: 100vh;
        box-shadow: 10px 1px 19px -2px rgba(130, 130, 130, 0.22);
    }
}
@media all and (max-width: 992px) {
    .userDropdown {
        display: none;
    }
}
.userDropdownButton {
    color: #fff;
    cursor: pointer;
}
.userDropdownButton:hover {
    color: rgba(255, 255, 255, 0.7);
}
.userDropdownSide {
    padding: 14px 20px;
    border-bottom: 1px solid #f1f1f1;
}
@media all and (min-width: 992px) {
    .userDropdownSide {
        display: none;
    }
}
.userDropdownSideButton {
    color: #222;
    cursor: pointer;
}
.userDropdownSideButton:hover {
    color: rgba(34, 34, 34, 0.7);
}
.layoutWrapper {
    position: relative;
}
.sidebarHeader {
    background: #d32027;
}
:global(.ant-menu-submenu-selected) {
    color: #d32027;
}
:global(.ant-menu-item a:hover),
:global(.ant-menu-item-selected a),
:global(.ant-menu-item-selected a:hover) {
    color: #d32027;
}
:global(.ant-menu-item-selected .anticon svg) {
    fill: #d32027;
}
:global(.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected) {
    background: rgba(211, 32, 39, 0.05);
}
:global(.ant-menu-vertical .ant-menu-item::after),
:global(.ant-menu-vertical-left .ant-menu-item::after),
:global(.ant-menu-vertical-right .ant-menu-item::after),
:global(.ant-menu-inline .ant-menu-item::after) {
    border-right: 3px solid #d32027;
}
:global(.ant-menu-light .ant-menu-item:hover),
:global(.ant-menu-light .ant-menu-item-active),
:global(.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open),
:global(.ant-menu-light .ant-menu-submenu-active),
:global(.ant-menu-light .ant-menu-submenu-title:hover) {
    color: #d32027;
}
:global(.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon),
:global(.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow) {
    color: #d32027;
}
