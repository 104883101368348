.title {
    font-weight: 600;
    text-transform: uppercase;
}
.checkIcon {
    color: #15903c;
}
.crossIcon {
    color: #ff5454;
}
