.clinic-modal .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    margin-right: 0 !important;
}

.clinic-modal .ant-form-item {
    margin-bottom: 12px !important;
}

.clinic-modal .ant-input[disabled],
.clinic-modal .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-input-number-disabled .ant-input-number-input,
.ant-input-number-disabled .ant-input-number-input,
.ant-radio-disabled + span  {
    color: var(--gray-9) !important;
}

.ant-radio-checked .ant-radio-inner::after{
    background-color: var(--gray-9) !important; 
}