.create-or-update-catalog-item-modal .ant-form-item-label {
    text-align: left;
}

.create-or-update-catalog-item-modal .ant-form-item {
    margin-bottom: 10px;
}

.create-or-update-catalog-item-modal .ant-form-item.no-margin {
    margin: 0;
}

.create-or-update-catalog-item-modal .ant-input-number {
    width: 100%;
}

.create-or-update-catalog-item-modal .ant-divider-horizontal {
    margin: 20px 0;
}

.create-or-update-catalog-item-modal .ant-divider-horizontal.ant-divider-with-text {
    margin: 10px 0;
}

.location-price_table .ant-table-thead > tr > th {
    padding: 8px;
}

.location-price_table .ant-table-tbody > tr.ant-table-row > td {
    padding: 8px;
}

.location-price_table .ant-table-tbody > tr.ant-table-row:hover > td {
    background: transparent;
}

.price-override-input input,
.price-adjustment-input input {
    text-align: right;
}
