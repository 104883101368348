.listHeader {
    padding: 12px 16px;
    padding-right: 40px;
    background-color: #f3f3f3;
    border: 1px solid #f0f0f0;
    border-bottom: 0;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 13px;
    background-color: #eaecec;
}
.tableWrapper {
    border: 1px solid #f0f0f0;
    border-top: 0;
}
