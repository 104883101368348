.location-counter {
    font-size: 12px;
    color: var(--gray-7);
    text-align: right;
    line-height: 22px;
}

.location-specific-buttons {
    display: flex;
    gap: var(--spacing-sm);
    margin-top: var(--spacing-sm);
}

.empty-state-text {
    color: var(--gray-9);
}

.empty-state-text > :first-child {
    font-size: 16px;
    font-weight: var(--font-medium);
}

.location-specific-items-container .ant-table-cell {
    padding: var(--spacing-sm);
}

.location-specific-items-container .ant-form-item {
    margin: 0;
}

.location-specific-items-container .ant-alert {
    align-items: flex-start;
}

.location-specific-items-container .ant-alert .anticon {
    margin-top: 4px;
}

.location-specific-items__delete-button {
    border: none;
    width: 20px;
    height: 22px;
}

.location-specific-items__delete-button,
.location-specific-items__delete-button:active,
.location-specific-items__delete-button:focus,
.location-specific-items__delete-button:hover {
    background: transparent;
}
